import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import ComponentBase from '../core/component-base';
import WindowState from '../core/window-state';
import tabbableElements from "../utils/tabbable";

export default class Slider extends ComponentBase {
  constructor(options = {}) {
    super('slider');

    /**
     * This class works in between app.js and swiper so custom functionality can be added.
     * Swiper has a robust API and generally we will hook it for all sliders on a site. These
     * customizations should live here
     *
     * @property {int} target
     *   Target DOM element that contains the slides to be used to init. per swiper docs, defaults to "swiper-container".
     * 
     * @property {int} slideSelector
     *   Class selector used to locate slides, defaults to "swiper-slide".
     * 
     * @property {int} preClass
     *   Class selector that will be added to slider if about to be initiated, defaults to "swiper-pre".
     *
     * @property {int} sliderOpts
     *   These are the options you want to supply to swiper.
     */

    this.options = {
      ...{
        target: 'swiper-container',
        destroyAt: undefined,
        slideSelector: 'swiper-slide',
        preClass: 'swiper-pre',
        minSlides: 2,
        sliderOpts: {
          slidesPerView: 'auto',
          watchSlidesVisibility: true,
          spaceBetween: 20,
          centeredSlides: false,
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
      },
      ...options,
    };
  }

  /**
   * Initialize each Slider.
   */
  init() {
    // configure Swiper to use modules
    Swiper.use([Navigation, Pagination]);

    this.items.forEach(slider => {

      // Look for slider container.
      let slideTarget;
      if (slider.classList.contains(slider.target)) {
        slideTarget = slider;
      } else {
        slideTarget = slider.querySelector(`.${slider.target}`);
      }

      if (!slideTarget) {
        return false;
      }

      // No point in running if less than minSlides.
      const slides = slideTarget.querySelectorAll(`.${slider.slideSelector}`);
      if (!slides || slides.length < slider.minSlides) {
        return false;
      }

      // Let the slider know it's about to be initiated.
      slideTarget.classList.add(slider.preClass);

      // Setup the swiper object
      let swiperSlider = {};

      // Set Swiper initialized to false by default
      swiperSlider.initialized = false;

      // Check destroyAt (mobile first) if we are below, init swiper
      if (
        window.innerWidth < slider.destroyAt ||
        (slider.destroyAt == undefined && swiperSlider.initialized == false)
      ) {
        swiperSlider = new Swiper(
          slideTarget,
          slider.sliderOpts,
        );
        if (
          swiperSlider.navigation.prevEl.disabled == true &&
          swiperSlider.navigation.nextEl.disabled == true
        ) {
          swiperSlider.navigation.prevEl.classList.add('fully-disable');
          swiperSlider.navigation.nextEl.classList.add('fully-disable');
        }

        // collect an array of all slides to parse indexes while tabbing
        const allSlides = slider.querySelectorAll(
          `.${swiperSlider.params.slideClass}`,
        );

        // Tabinex assignment
        // ** watchSlidesVisibility MUST BE SET TO TRUE **
        function tabindexAccessibility() {
          allSlides.forEach(slide => {
            if (
              slide.classList.contains(swiperSlider.params.slideVisibleClass)
            ) {
              slide.setAttribute('aria-hidden', 'false');
              slide.removeAttribute('tabindex');
              slide
                .querySelectorAll(tabbableElements)
                .forEach(tabbableElement => {
                  tabbableElement.removeAttribute('tabindex');
                });
            } else {
              slide.setAttribute('aria-hidden', 'true');
              slide.setAttribute('tabindex', '-1');
              slide
                .querySelectorAll(tabbableElements)
                .forEach(tabbableElement => {
                  tabbableElement.setAttribute('tabindex', '-1');
                });
            }
          });
        }

        // Decide what slide should be tabbable
        tabindexAccessibility();

        // Cherry pick events for when to
        swiperSlider.onAny(function (event) {
          if (event == 'update' || event == 'slideChange') {
            tabindexAccessibility();
          }
        });
      }
      // on window resize check if swiper init and destroyAt
      if (slider.destroyAt != undefined) {
        WindowState.on('resize', () => {
          // If swiper is init, and we resize above the destroyAt size, destroy Swiper
          if (
            swiperSlider.initialized == true &&
            window.innerWidth > slider.destroyAt
          ) {
            swiperSlider.destroy(true, true);
          }
          // If swiper is not init, and we resize below the destroyAt size, init Swiper
          if (
            swiperSlider.initialized != true &&
            window.innerWidth < slider.destroyAt
          ) {
            swiperSlider = new Swiper(
              slideTarget,
              slider.sliderOpts,
            );
          }
        });
      }
    });
  }
}
