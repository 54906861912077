/**
 * @file
 * String to HTML File function.
 */

/**
 * Create an HTML Element from a string.
 *
 * @param {HTMLElement} element
 *   The element to append the new markup.
 * @param {string} str
 *   The markup string to create.
 */
export function createHtmlElement(element, str) {
  const html = document.createElement('div');
  html.classList.add('js-process--create-element');
  html.innerHTML = str;
  element.appendChild(html);
}
