import ButtonToggle from "./button-toggle";
import { getCookie, setCookie } from '../utils/handle-cookies';
import { removeTabIndexContainer, hideFocusContainer } from "../utils/tab-index";

export default function CookieBanner() {
  const cookieBanner = document.getElementById("cookie-banner");

  // If the cookie banner is present init.
  if (cookieBanner === undefined) {
    return;
  }

  // Set Cookie Banners Cookies and Toggle
  const dismissCookieTarget = document.querySelector('.btn--dismiss-cookie');
  const dismissCookieToggle = new ButtonToggle();
  dismissCookieToggle.add('.btn--dismiss-cookie').run();

  // When user clicks dismiss button...
  dismissCookieTarget.addEventListener('click', () => {
    // Set an accepted cookie.
    setCookie('CookiesAcknowledged', true, '365', '/');
    // Hide focusable elements from focus.
    hideFocusContainer(cookieBanner);
  })

  // Check cookies to see if they've been accepted.
  if (getCookie('CookiesAcknowledged') === 'true') {
    dismissCookieTarget.toggleButton(true);
  } else {
    // Remove tab index "non focus" from focusable elements.
    removeTabIndexContainer(cookieBanner);
  }
}