/**
 * @file
 * Accordion class.
 */

 import ComponentBase from '../core/component-base';
 import WindowState from '../core/window-state';
 import { slideDown, slideUp } from '../utils/animations';

 /**
  * The Accordion class provides an easy way to create accordions with smart text
  * toggling for accessibility, and also the option of creating an accordion
  * which is only visible below a breakpoint.
  */
 export default class Accordion extends ComponentBase {
   /**
    * Set the object's initial state.
    *
    * @constructor
    * @param {Object} options
    *   Accordion options. See object definition in the constructor below.
    */
   constructor(options = {}) {
     super('accordion');

     /**
      * Create the options from supplied options and defaults. Options can be
      * specified on object creation by passing an object, or inline as data
      * attributes on the accordion DOM element. Data attribute options are
      * kebab-cased versions of the options below, prefixed with data-accordion.
      *
      * @property {int} duration
      *   The duration of the toggle animation, in ms. Defaults to 400, or the
      *   value of the [data-accordion-duration] attribute.
      * @property {string} openClass
      *   The class to add to the calling element when the accordion is opened.
      *   Defaults to 'accordion--open', or the value of the
      *   [data-accordion-open-class] attribute.
      * @property {string} buttonSelector
      *   The selector of the accordion button. Defaults to '.accordion__button',
      *   or the value of the [data-accordion-button-selector] attribute.
      * @property {string} contentSelector
      *   The selector of the accordion content. Defaults to
      *   '.accordion__content', or the value of the
      *   [data-accordion-content-selector] attribute.
      * @property {int} breakpoint
      *   The accordion breakpoint, at or above which the accordion does not
      *   display. Useful for making a mobile-only accordion. Set to -1 to never
      *   create a mobile accordion. Defaults to -1, or the value of
      *   the [data-accordion-breakpoint] attribute.
      * @property {Boolean} escapable
      *   Whether or not the accordion should be closeable with an escape key press.
      *   Defaults to false, or the value of the [data-button-escapable] attribute.
      * @property {string} group
      *   The accordion group, which is used to determine the initial open state
      *   of items within that group. Set to '' to not create a group. Defaults
      *   to '', or the value of the [data-accordion-group] attribute.
      * @property {string} open
      *   Set accordions to be open on page load. Can be 'self', which opens the
      *   calling accordion, or a comma-separated string of values which are used
      *   in conjunction with the group property to open accordions within a
      *   group. Valid values for open are 'first' and 'last', as well as 1-index
      *   integers. Defaults to '', or the value of the [data-accordion-open]
      *   attribute.
      * @property {int} multipleOpen
      *   Allow only one accordion to be open at a time. Must be used in
      *   conjunction with accordion groups. Use 1 (true) enable, or 0 (false) to
      *   disable. Defaults to 1, or the value of the
      *   [data-accordion-multiple-open] attribute.
      */
     this.options = {
       ...{
         duration: 400,
         openClass: 'accordion--open',
         openText: '',
         buttonSelector: '.accordion__button',
         buttonTextSelector: '.accordion__button-text',
         contentSelector: '.accordion__content',
         breakpoint: -1,
         escapable: 0,
         group: '',
         open: '',
         multipleOpen: 1,
       },
       ...options,
     };

     /**
      * The accordion group and group items.
      *
      * The object keys are the group names, and the value is an array of
      * accordion HTMLElements in that group.
      *
      * @type {Object}
      */
     this.groupItems = {};

     /**
      * The accordion group open item indecies.
      *
      * The object keys are the group names, and the value is a 1-index array of
      * integers corresponding to the initial open items.
      *
      * @type {Object}
      */
     this.groupOpenItems = {};
   }

   /**
    * Initialize each accordion.
    */
   init() {
     this.setupGroups();
     this.items.forEach(accordion => {
       /**
        * Open or close an accordion.
        *
        * @param {HTMLElement} accordion
        *   The accordion to toggle.
        * @param {string} action
        *   The toggle action, either 'open' or 'close'.
        * @param {int|null} durationOverride
        *   Override the animation duration.
       */
       
       accordion.escapable = parseInt(accordion.escapable, 10);
       
       const button = accordion.querySelector(accordion.buttonSelector);
       const buttonText = accordion.querySelector(accordion.buttonTextSelector);
       const content = accordion.querySelector(accordion.contentSelector);
       
       // When the accordion is setup we want to toggle aria expanded to be false
       button.setAttribute('aria-expanded', false);

       // Store original button text.
       const buttonTextValue = buttonText.innerText;
       
       accordion.toggleAccordion = (action, durationOverride = null, focus = false) => {
         
        // Run the toggle actions.
        if (action === 'close') {
          accordion.classList.remove(accordion.openClass);
          slideUp(
            content,
            durationOverride !== null ? durationOverride : accordion.duration,
          );
          if (accordion.openText) {
            buttonText.innerText = buttonTextValue;
          }
          if (focus) {
            button.focus();
          }
          accordion.isOpen = false;
        }
         if (action === 'open') {
           accordion.classList.add(accordion.openClass);
           slideDown(
             content,
             durationOverride !== null ? durationOverride : accordion.duration,
           );
           if (accordion.openText) {
             buttonText.innerText = accordion.openText;
           }
           accordion.isOpen = true;
         }

         // Update aria expanded.
         const ariaExpanded = action === 'open' ? 'true' : 'false';
         button.setAttribute('aria-expanded', ariaExpanded);

         /**
          * Emit an 'toggle' event on clicks.
          *
          * @type {Object}
          * @property event
          *   The triggering toggle event.
          * @property {HTMLElement} accordion
          *   The accordion that triggered the event.
          */
         this.emit('toggle', { accordion });
       };

       // If the item declares it should be open initially, open it.
       if (accordion.open === 'self') {
         accordion.toggleAccordion('open');
       }

       // If the item is in a group and should be open on load, open it.
       if (this.groupItems[accordion.group]) {
         this.groupItems[accordion.group].forEach((item, key) => {
           // Check that the current item is the accordion being processed in
           // init, and that the accordion is marked to be open on load.
           if (
             item === accordion &&
             this.groupOpenItems[accordion.group].indexOf(key + 1) !== -1
           ) {
             accordion.toggleAccordion('open', 0);
           }
         });
       }

       // If the item's hash is in the url on load, open it.
       if (accordion.id && window.location.hash.substr(1) === accordion.id) {
         accordion.toggleAccordion('open', 0);
       }

       // Check if escapable is set to true for content.
       if (accordion.escapable === 1) {
         content.addEventListener('keydown', e => {
           if (!accordion.isOpen) {
             return false;
           }

           // If escape has been clicked.
           if (e.key === 'Escape') {
             e.stopPropagation();
             accordion.toggleAccordion('close', null, true);
           }
         });
        }

       // Add the click event.
       button.removeAttribute('disabled');
       button.addEventListener('click', () => {
         if (accordion.isOpen) {
           accordion.toggleAccordion('close');
         } else {
           accordion.toggleAccordion('open');
           // Close all open accordions if the option is set.
           if (
             accordion.multipleOpen === '0' &&
             this.groupItems[accordion.group]
           ) {
             this.groupItems[accordion.group].forEach(item => {
               if (item !== accordion) {
                 item.toggleAccordion('close');
               }
             });
           }
         }
       });

       // Update the accordion after init. This is needed because WindowState
       // events do not fire after ajax loads.
       this.update(WindowState.width);
     });
   }

   /**
    * Update each accordion on resize.
    */
   resize() {
     WindowState.on('resize', resize => {
       this.update(resize.width);
     });
   }

   /**
    * Update the accordion state.
    *
    * @param {int} width
    *   The current window width.
    */
   update(width) {
     this.items.forEach(accordion => {
       const button = accordion.querySelector(accordion.buttonSelector);
       // Add the mobile accordion behavior.
       if (accordion.breakpoint > -1) {

         // Hide focus from specific elements on mobile accordion.
         const hideFocusElements = accordion.querySelectorAll('.accordion-mobile-hide-focus');

         if (width < accordion.breakpoint) {
           button.removeAttribute('disabled');
           accordion.setAttribute('aria-hidden', 'false');
           accordion.disabled = false;
           if (hideFocusElements && hideFocusElements.length) {
             hideFocusElements.forEach(elem => {
               elem.setAttribute('tabindex', '-1');
             });
           }
         } else {
           button.setAttribute('disabled', true);
           accordion.disabled = true;
           if (hideFocusElements && hideFocusElements.length) {
             hideFocusElements.forEach(elem => {
              elem.removeAttribute('tabindex');
             });
           }
         }
       }
     });
   }

   /**
    * Setup the accordion groups and initial states.
    */
   setupGroups() {
     this.items.forEach(accordion => {
       // Only run if the accordion has a group defined and the group has not
       // had it's definitions set yet.
       if (accordion.group && !(accordion.group in this.groupItems)) {
         // Get all accordions in this group.
         this.groupItems[accordion.group] = this.items.filter(
           item => item.group === accordion.group,
         );

         // Get a list of the open items for the group as a 1-index array.
         // Convert 'first' and 'last' options to integers.
         this.groupOpenItems[accordion.group] = accordion.open
           .split(',')
           .map(item => {
             if (item === 'first') {
               return 1;
             }
             if (item === 'last') {
               return this.groupItems[accordion.group].length;
             }
             // Ensure we return an integer, never a string.
             return Number(item);
           });
       }
     });
   }
 }
