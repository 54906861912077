/**
 * @file
 * JavaScript for video behaviors.
 *
 */

import ComponentBase from '../core/component-base';
import ButtonToggle from './button-toggle';
import { createHtmlElement } from '../utils/string-to-html';

export default class OhoVideo extends ComponentBase {

  /**
   * Set the object's initial state.
   *
   * @constructor
   * @param {Object} options
   *   video options. See object definition in the constructor below.
   */
  constructor(options = {}) {
    super('video');

    /**
     * Create the options from supplied options and defaults. Options can be
     * specificed on object creation by passing an object.
     *
     * @property {int} componentsWrapper
     *   The element that wraps the video and button, just below the init
     *   or the value of the [data-components-wrapper-selector] attribute.
     *
     * @property {int} videoSelector
     *   The <video> to select
     *   or the value of the [data-video-selector] attribute.
     *
     * @property {HTMLelement} playButtonSelector
     *   The button to select that will play/pause the video
     *   or the value of the [data-video-play-button-selector] attribute.
     *
     * @property {bool} autoplay
     *   if the video should autoplay,
     *   or the value of the [data-video-autoplay] attribute.
     *
     * @property {val} autoplayBreakpoint
     *   The min width of when the video should autoplay,
     *   or the value of the [data-video-autoplay-breakpoint] attribute.
     *
     * @property {bool} controls
     *   The min width of when the video should autoplay,
     *   or the value of the [data-video-autoplay-breakpoint] attribute.
     */
    this.options = {
      ...{
        componentsWrapper: '.autoplay-video-container',
        videoSelector: 'video',
        iframeSelector: 'iframe',
        playButtonSelector: '.video-play-button',
        playButtonOpenClassElement: '.hero',
        autoplay: true,
        autoplayBreakpoint: -1,
        controls: true,
        playButtonToggle: new ButtonToggle(),
      },
      ...options,
    };
  }

  init() {
    this.items.forEach(video => {

      const videoPlaySelector = video.querySelector(video.playButtonSelector);
      video.playButtonToggle.add(this.options.playButtonSelector).run();

      // We add autoplay if above a certain size. (10/30/2019 Chrome will always pass touchevents as true)
      /**
       * Add the Autoplay to videos with videos
       */
      const videoTarget = video.querySelector(video.videoSelector);
      const setAutoplay = status => {
        if (status == true) {
          if (videoTarget) {
            videoTarget.setAttribute('autoplay', 'true');
            videoTarget.setAttribute('preload', 'true');
          }

        } else if (status == false) {
          if (videoTarget) {
            video.setAttribute('autoplay', 'true');
            video.setAttribute('preload', 'true');
          }

        }
      };
      var iframe = document.getElementById('iframe');
      if (iframe == null) {
        // this is not an iframe
      } else {
        var iframeSrc = iframe.src;
      }


      // If there's an autoplay video, and we're within the breakpoint autoplay the video otherwise remove the attrs
      if (
        video.autoplay == true &&
        window.innerWidth > video.autoplayBreakpoint
      ) {
        setAutoplay(true);
        videoPlaySelector.toggleButton(true);
        if (iframe) {
          iframe.src = iframeSrc + "&autoplay=1";
        }
      } else {
        setAutoplay(false);
        videoPlaySelector.toggleButton(false);
      }

      video.playButtonToggle.on('toggle', event => {

        event.button.setAttribute('aria-pressed', event.button.isToggled)

        if (event.button.isToggled == true) {
          // When the video is playing, we want the button to read "Pause"
          event.button.setAttribute('aria-label', 'Pause');

          if (videoTarget) {
            videoTarget.play();
          }
          if (iframe) {
            iframe.src = iframeSrc + "&autoplay=1";
          }

        } else {
          if (videoTarget) {
            videoTarget.pause();
          }
          if (iframe) {
            iframe.src = iframeSrc;
          }

          // When the video is paused, we want the button to read "Play"
          event.button.setAttribute('aria-label', 'Play');
        }
      });
    });
  }
}
