// An array of tabbable selectors, plus anything with a positive tabindex.
// @see https://www.w3.org/TR/html5/editing.html#the-tabindex-attribute
const tabbableElements = [
	'a[href]',
	'link[href]',
	'button:not(:disabled)',
	'input:not([type="hidden"])',
	'select',
	'textarea',
	'[draggable]',
	'audio[controls]',
	'video[controls]',
	'[tabindex]:not([tabindex="-1"])',
  ];
	  
export default tabbableElements;