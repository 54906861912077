/**
 * @file
 * MoreOrLess class.
 */

 import ButtonToggle from './button-toggle';
 import ComponentBase from '../core/component-base';
 
 /**
  * The MoreOrLess class provides an easy way to accessibly expand and collapse
  * a section on click.
  */
 export default class MoreOrLess extends ComponentBase {
   /**
	* Set the object's initial state.
	*
	* @constructor
	* @param {Object} options
	*   MoreOrLess options. See object definition in the constructor below.
	*/
   constructor(options = {}) {
	 super('more-or-less');
 
	 /**
	  * Create the options from supplied options and defaults. Options can be
	  * specificed on object creation by passing an object, or inline as data
	  * attributes on the more or less DOM element. Data attribute options are
	  * kebab-cased versions of the options below, prefixed with
	  * data-more-or-less.
	  *
	  * @property {String} buttonSelector
	  *   The selector of the toggle button. Defaults to '.more-less__toggle', or
	  *   the value of the [data-more-or-less-button-selector] attribute.
	  *
	  * @property {Number} initialVisibleItems
	  *   The number of initial visibile Items. This option only tells the more-less to init.
	  *   Handle the desired nth-child hide/show in _more-or-less.scss. Defaults to 3, or the value of the
	  *   [data-more-or-less-initial-visible-items] attribute. MoreOrLess will only use this options if
	  *   @property {String} calcMethod is set to 'items'
	  *
	  * @property {String} enableAt
	  *   The window width size to enable the more/less. This attribute is mobile first, so we check if the window width greater than enableAt
	  *   Sometimes if items is passed to calcMethod, it becomes a slider in mobile.
	  *   Defaults to 0, or the value of the [data-more-or-less-enable-at] attribute.
 
	  */
	 this.options = {
	   ...{
       buttonSelector: '.more-less__toggle',
       initialVisibleItems: 9,
       contentSelector: '.more-less__content',
       itemSelector: '.more-less__item',
       enableAt: 640,
	   },
	   ...options,
	 };
   }
 
   /**
	  * Initialize each more or less.
	  */
   init() {
	 this.items.forEach(moreOrLess => {
	   if (window.innerWidth > moreOrLess.enableAt) {
		 MoreOrLess.initMoreLess(moreOrLess);
	   }
	 });
   }
 
   /**
	* A static function to handle all the MoreOrLess logic
	*
	* @param {object} moreOrLess
	* The moreOrLess object to query
	*/
 
   static initMoreLess(moreOrLess) {
	 // Add button toggling.
	 const moreOrLessToggle = new ButtonToggle();
	 moreOrLessToggle.add(moreOrLess.buttonSelector, moreOrLess).run();
  
	 // Store targets as variables to access more easily
	 const moreOrLessToggleTarget = moreOrLess.querySelector(
	   moreOrLess.buttonSelector,
	 );
	 const moreOrLesscontentTarget = moreOrLess.querySelector(
	   moreOrLess.contentSelector,
	 );
	 const moreOrLessItems = moreOrLess.querySelectorAll(
	   moreOrLess.itemSelector,
	 );
 
	 // add fixed attributes to key elemements for cross module integration
	 moreOrLessToggleTarget.setAttribute('data-more-less-button', 'true');
	 moreOrLessItems.forEach(item =>
	   item.setAttribute('data-more-less-item', 'true'),
	 );
	 moreOrLesscontentTarget.setAttribute('data-more-less-content', 'true');
 
	 /**
	  * Set threshold queries to return true/false
	  */
	   const totalItems = moreOrLessItems.length;
	 const totalItemsThreshold = totalItems > moreOrLess.initialVisibleItems;
 
	 /**
	  * Rely more heavily on CSS by assigning attributes and CSS rules
	  */

    // If the calc method is items, and the total number of items is more than the initial visible items
    if (totalItemsThreshold === true) {
	  moreOrLess.setAttribute('data-more-less-init', 'items');
   }
 }
}
