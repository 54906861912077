/**
 * @file
 * ButtonToggle class.
 */
import ComponentBase from '../core/component-base';
import WindowState from '../core/window-state';
import tabbableElements from "../utils/tabbable";

/**
 * The ButtonToggle class provides an easy way to add a toggleable button. The
 * class toggles a class on <body>, updates the button label based on state,
 * takes care of enabling or disabling based on screen width, and provides an
 * event which can be used to trigger more advanced behaviors.
 *
 * @fires ButtonToggle#toggle
 */
export default class ButtonToggle extends ComponentBase {
  /**
   * Set the object's initial state.
   *
   * @constructor
   * @param {Object} options
   *   ButtonToggle options. See object definition in the constructor below.
   */
  constructor(options = {}) {
    super('button');
    /**
     * Create the options from supplied options and defaults. Options can be
     * specificed on object creation by passing an object, or inline as data
     * attributes on the button DOM element. Data attribute options are
     * kebab-cased versions of the options below, prefixed with data-button.
     *
     * @property {Number} enableAt
     *   The screen width at which to enable the button behaviors. Set 0 to
     *   always enable. Defaults to 0, or the value of the
     *   [data-button-enable-at] attribute.
     * @property {Number} disableAt
     *   The screen width at which to disable the button behaviors. Set -1 to
     *   never disable. Defaults to -1, or the value of the
     *   [data-button-disable-at] attribute.
     * @property {String} openClass
     *   Class to add to <body> when the button is clicked. Defaults to '', or
     *   the value of the [data-button-open-class] attribute.
     * @property {String} openClassElement
     *   The closest parent element to add the open class to. Defaults to
     *   'body', or the value of the [data-button-open-class-element] attribute.
     *   If the element is not found in the parent tree, this reverts to body.
     * @property {Number} duration
     *   The duration of the toggle animations, in ms. Defaults to 400, or the
     *   value of the [data-button-duration] attribute.
     * @property {Boolean} escapable
     *   Whether or not the button should be closeable with an escape key press.
     *   Defaults to true, or the value of the [data-button-escapable]
     *   attribute.
     * @property {Boolean} closeOnClick
     *   Whether or not the button should be closeable when user clicks outside focus target.
     *   Defaults to false, or the value of the [data-button-close-on-click]
     *   attribute.
     * @property {Boolean} closeOnLeave
     *   Whether or not the button should be closeable when focus leaves focus target.
     *   Defaults to false, or the value of the [data-button-close-on-leave]
     *   attribute.
     * @property {Boolean} trapFocus
     *   Whether or not keyboard focus should be trapped when user enters.
     *   Defaults to false, or the value of the [data-button-close-on-leave]
     *   attribute.
     */
    this.options = {
      ...{
        enableAt: 0,
        disableAt: -1,
        openClass: '',
        openClassElement: 'body',
		textSelector: '.button__text',
		toggledText: '',
		toggledLabel: '',
        duration: 400,
        escapable: 1,
        closeOnClick: 0,
        closeOnLeave: 0,
        trapFocus: 0,
      },
      ...options,
	};
  }

  /**
   * Add the button behaviors.
   */
  init() {
    this.items.forEach(button => {		
      button.init = true;
      // Track the current state, clicked unclicked.
      button.isToggled = false;
      // Track if the button has been clicked once.
      button.isClicked = false;
      // Add a click event listener to the button.
      button.addEventListener('click', () => {
        button.toggleButton();
      });

      button.escapable = parseInt(button.escapable, 10);
      button.closeOnClick = parseInt(button.closeOnClick, 10);
      button.closeOnLeave = parseInt(button.closeOnLeave, 10);
      button.trapFocus = parseInt(button.trapFocus, 10);

		button.defaultLabel = button.getAttribute("aria-label");
      const buttonText = button.querySelector(button.textSelector);
      if (buttonText) {
        buttonText.defaultText = buttonText.innerText;
      }

      // When the button is setup, we want to toggle aria expanded.
      if (button.getAttribute('aria-expanded') != null) { button.setAttribute('aria-expanded', button.isToggled) }

      const focusTarget = document.getElementById(button.getAttribute('aria-controls'));
      if (focusTarget) {

        // Check if escapable is set to true.
        if (button.escapable === 1 || button.trapFocus === 1) {
          focusTarget.addEventListener('keydown', e => {
            // Only necessary if button is toggled.
            if (!button.isToggled) {
              return;
            }

            // If escape has been clicked.
			      if (e.key === 'Escape' && button.escapable === 1) {
				      e.stopPropagation();
              button.toggleButton(false);
            }

            // We only "trap focus" with the tab key.
            if (button.trapFocus !== 1 || e.key !== 'Tab') {
              return;
            }
				
            const focusableEls = focusTarget.querySelectorAll(tabbableElements);
            if (!focusableEls || !focusableEls.length) {
              return false;
            }

            // Get the first focusable element.
            const firstFocusableEl = focusableEls[0];

            // We have to do a test to determine the last visible element.
            let lastFocusableElIndex = focusableEls.length - 1;
            let lastFocusableEl = focusableEls[lastFocusableElIndex];
            let lastFocusableElVisible = lastFocusableEl.offsetParent;
            while (!lastFocusableElVisible && lastFocusableElIndex >= 0) {
              lastFocusableElIndex -= 1;
              lastFocusableEl = focusableEls[lastFocusableElIndex];
              lastFocusableElVisible = lastFocusableEl.offsetParent;
            }

            // Means we're tabbing out of the beginning.
            if (e.shiftKey) {
              if (document.activeElement === firstFocusableEl) {
                if (lastFocusableElVisible) {
                  lastFocusableEl.focus();
                  e.preventDefault();
                }
                return false;
              } 
            } 

            // Means we're tabbing out of the end.
            if (document.activeElement === lastFocusableEl) {
              firstFocusableEl.focus();
              e.preventDefault();
              return false;
            }
          });
        }

        if (button.closeOnClick === 1) {

          // Close button if user clicks outside focus target.
          document.addEventListener('click', event => {

            // Don't close if clicked within button.
            if (!button.isToggled || button.contains(event.target)) {
              return false;
            }

            // Don't close if interacting with button or focus target.
            if (button === event.target || focusTarget === event.target) {
              return false;
            }

            if (!focusTarget.contains(event.target)) {
              button.toggleButton(false, false);
            }
          });
        }

        if (button.closeOnLeave === 1) {
          document.addEventListener('keyup', e => {
            // Only concerned with the Tab key and toggled buttons.
            if (!button.isToggled || e.key !== 'Tab') {
              return false;
            }
            
            //  Only concerned if outside the target.
            if (focusTarget.contains(document.activeElement)) {
              return false;
            }

            // This means weve left the target. So close the button.
            button.toggleButton(false, false);
          });
        }
      }

      /**
       * Toggle a button.
       *
       * @param {bool|null} state
       *   The state to toggle, true corresponds to a clicked state.
       * @param {bool|null} setFocus
       *   Whether to refocus The User Cursor when the button is active, defaults to true
       *   setFocus is set to false when we set activeTrail, and accordion states.
       *
       */
      button.toggleButton = (
        state = null,
        setFocus = true
      ) => {

        // Only if the button is not disabled.
        if (button.hasAttribute('disabled')) {
          return;
        }

        // Update the state.
        button.isToggled = state !== null ? state : !button.isToggled;
        
		  // Set to clicked on initial click or manual toggle.
        button.isClicked = state !== false;
        
		  // Update the text.
        // The element which the open class is added defaults to body.
        let classElement = document.body;
        
		    // If a selector is specified, and is not body, get the closest parent
        // of that selector and use it, or body if there is no parent found.
        if (
          button.openClassElement.length &&
          button.openClassElement !== 'body'
        ) {
          classElement = button.closest(button.openClassElement)
            ? button.closest(button.openClassElement)
            : document.body;
		    }
		
		// Change button text
		  if (buttonText && button.toggledText) {
			if (button.isToggled) {
				buttonText.innerText = button.toggledText;
			} else {
				buttonText.innerText = buttonText.defaultText;
			}
		  }
		  if (button.toggledLabel) {
			if (button.isToggled) {
				button.setAttribute('aria-label', button.toggledLabel);
			} else {
				buttonText.innerText = buttonText.defaultText;
				button.setAttribute('aria-label', button.defaultLabel);
			}
		}
        
		    // Toggle the class.
        if (button.isToggled) {
          classElement.classList.add(button.openClass);
        } else {
          classElement.classList.remove(button.openClass);
        }

        // Update expanded aria attributes based on toggle type
        if (button.getAttribute('aria-expanded') != null) { button.setAttribute('aria-expanded', button.isToggled) }
        if (button.getAttribute('aria-pressed') != null) { button.setAttribute('aria-pressed', button.isToggled) };

        // Refocus the user cursor
		    const controlType = button.getAttribute('data-toggle-type');

        // Check to make sure the button is toggled, and the button has a focus target.
        if (setFocus === true) {
          if (button.isToggled) {
            if (focusTarget != null) {

              /**
                * W3 states if aria-haspopup is true, screenreaders identify this as a menu button.
                * If its present W3 shows an example of refocusing the user onto the first menu open
                */
              if (controlType === 'menu') {
                const firstChild = focusTarget.querySelectorAll(tabbableElements)[0];
                if (firstChild) {
                  firstChild.focus();
                }
              }
            }
          } else {
            button.focus();
          }
        }

        /**
         * Emit an 'toggle' event on clicks.
         *
         * @type {Object}
         * @property event
         *   The triggering toggle event.
         * @property {HTMLElement} button
         *   The button that triggered the event.
         */
        this.emit('toggle', { button });

      };
    });
  }

  /**
   * Update each button on resize, disabling the button if appropriate.
   */
  resize() {
    WindowState.on('resize', resize => {
      this.items.forEach(button => {
        // Determine the max breakpoint. If disableAt is -1, choose an
        // unrealistically high number.
        const maxBreakpoint =
          button.disableAt == -1 ? 99999 : button.disableAt;
        const disabled = !(
          button.enableAt <= resize.width && resize.width < maxBreakpoint
        );
        if (disabled) {
          button.setAttribute('disabled', 'disabled');
        } else {
          button.removeAttribute('disabled');
        }
      });
    });
  }
}
