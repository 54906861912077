/**
 * @file
 * Exported functions to more easily interact with cookies IE reading/setting/deleting.
 */

/**
 * Set a Cookie
 *
 * @param {name} string
 *   The name of the cookie to set. IE whoNeedsInsurance
 * @param {value} string
 *   The value of the cookie to set. IE HouseholdCoverageType=individual
 * @param {days} string
 *   How long to store the cookie for IE days = 7
 * @param {path} string
 *   Where in the site the cookies are applied IE '/'
 */

export function setCookie(name, value, days = 7, path = '/') {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = name + '=' + encodeURIComponent(value) + '; SameSite=Strict; expires=' + expires + '; path=' + path
}

/**
 * get a Cookie
 * Search for a cookie by name and return the values
 *
 * @param {name} string
 *   The name of the cookie to set. IE whoNeedsInsurance
 */
export function getCookie(name) {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '')
}

/**
 * Delete a Cookie
 * Search for a cookie by name and path to reset its values
 *
 * @param {name} string
 *   The name of the cookie to set. IE whoNeedsInsurance
 * @param {path} string
 *   Where in the site the cookies are applied IE '/'
 */
export function deleteCookie(name, path) {
  setCookie(name, '', -1, path)
}