/**
 * @file
 * Prefers Reduced Motion JS Utility
 */

/**
 * Check if the user prefers reduced motion. returns true/false
 * Example use if(prefersReducedMotion() == false) { JS based anims }
 */
function prefersReducedMotion() {
  const QUERY = '(prefers-reduced-motion: no-preference)';
  const mediaQueryList = window.matchMedia(QUERY);
  return !mediaQueryList.matches;
}
export default prefersReducedMotion;