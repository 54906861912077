/**
 * @file
 * AccessibleMenu class.
 */

import ButtonToggle from './button-toggle';
import ComponentBase from '../core/component-base';

/**
 * The AccessibleMenu class makes any menu tree accessible by keyboard
 * navigation. The class adds buttons with descriptive labels inside menu items
 * with children, and toggles classes on the button parent.
 */
export default class AccessibleMenu extends ComponentBase {
  /**
   * Set the object's initial state.
   *
   * @constructor
   * @param {Object} options
   *   Menu options. See object definition in the constructor below.
   */
  constructor(options = {}) {
    super('menu');

    /**
     * Create the options from supplied options and defaults. Options can be
     * specificed on object creation by passing an object, or inline as data
     * attributes on the menu DOM element. Data attribute options are
     * kebab-cased versions of the options below, prefixed with data-menu.
     *
     * @property {String} buttonClass
     *   The class added to the inserted button. Defaults to
     *   'menu-item__button', or the value of the [data-menu-button-class]
     *   attribute.
     * @property {String} hasItemsSelector
     *   The selectors of menu items which have children. Defaults to
     *   '.menu-item--children', or the value of the [data-menu-has-items-selector] attribute.
     * @property {String} openClass
     *   The class to add to open menu items, specified by hasItemsSelector.
     *   Defaults to 'menu-item--open', or the value of the
     *   [data-menu-open-class] attribute.
     * @property {RegExp} regex
     *   The regex search string to look for in the close and open text.
     *   Defaults to /%s/, or the value of the [data-menu-regex] attribute.
     * @property {String} label
     *   The text pattern for the aria-label button attribute. Can use the regex string for
     *   replacement. Defaults to 'Toggle the %s menu', or the value of the
     *   [data-menu-toggle-text] attribute.
     * @property {Boolean} escapable
     *   Whether or not the menu items should be closeable with an escape key
     *   press. Defaults to true, or the value of the [data-menu-escapable]
     *   attribute.
     * @property {Boolean} expandActiveTrail
     *   Expand the menu active trail items on load. Defaults to false, or the
     *   value of the [data-expand-active-trail] attribute.
     * @property {String} activeTrailSelector
     *   The active trail menu item selector, used to determine the active trail
     *   if expandActiveTrail is set to true. Defaults to
     *   '.menu-item--active-trail', or the value of the
     *   [data-menu-active-trail-selector] attribute.
     */
    this.options = {
      ...{
        actionsClass: 'menu-item__actions',
        buttonClass: 'menu-item__button',
        hasItemsSelector: '.menu-item--children',
        openClass: 'menu-item--open',
        regex: /%s/,
        label: 'Toggle the %s menu',
        escapable: 1,
        expandActiveTrail: 0,
        activeTrailSelector: '.menu-item--active-trail',
      },
      ...options,
    };
  }

  /**
   * Add the accessible menu behaviors for each menu.
   */
  init() {
    this.items.forEach(menu => {
      const menuItemsWithChildren = menu.querySelectorAll(
        menu.hasItemsSelector,
      );

      menuItemsWithChildren.forEach(menuItem => {
        // The menu item's button.
        const button = menuItem.querySelector(
          `.${menu.actionsClass} .${menu.buttonClass}`,
		);
        button.removeAttribute('disabled');
      });

      // Run button toggle if buttons were added.
      if (menuItemsWithChildren.length) {
        const buttonToggle = new ButtonToggle();
        buttonToggle.add(`.${menu.buttonClass}`, menu).run();
      }

      // If the active trail should be expanded, then toggle the buttons in the
      // menu's active trail.
      if (menu.expandActiveTrail && menuItemsWithChildren.length) {
        const activeTrail = menu.querySelectorAll(
          `${menu.activeTrailSelector} .${menu.buttonClass}`,
        );
        activeTrail.forEach(button => {
          button.toggleButton(true, false);
        });
      }
    });
  }
}
