/**
 * @file
 * ImageGrid class.
 */

 import Shuffle from 'shufflejs';
 import Swiper, { Navigation, Pagination } from 'swiper';
 import ComponentBase from '../core/component-base';
 import WindowState from '../core/window-state';
 import 'swiper/swiper-bundle.css';
 import 'core-js/features/object/assign';
 import '@fancyapps/ui/dist/fancybox.css';
 
 /**
  * The ImageGrid class provides a way to make a masonry image grid which
  * transitions to a slider on mobile.
  */
 export default class ImageGrid extends ComponentBase {
   /**
	* Set the object's initial state.
	*
	* @constructor
	* @param {Object} options
	*   ImageGrid options. See object definition in the constructor below.
	*/
   constructor(options = {}) {
	 super('image-grid');
 
	 /**
	  * Create the options from supplied options and defaults. Options can be
	  * specificed on object creation by passing an object, or inline as data
	  * attributes on the image grid DOM element. Data attribute options are
	  * kebab-cased versions of the options below, prefixed with data-image-grid.
	  *
	  * @property {int} enableAt
	  *   The width at which the grid is initialized. Below this the
	  *   grid is a slider. Set to 0 to never use a slider. Defaults to 640, or
	  *   the value of the [data-image-grid-enable-at] attribute.
	  * @property {Object} sliderOpts
	  *   Glide options. This key is passed directly to Swiper on initialization.
	  *   Defaults to {}.
	  * @property {Object} shuffle
	  *   Shuffle options. This key is passed directly to Shuffle on initialization.
	  *   Defaults to an itemSelector of '.image-grid-item' & an itemSelectorLink .image-grid-item__link.
	  *
	  * @see https://glidejs.com/docs/options/
	  * @see https://vestride.github.io/Shuffle/#options
	  */
	 this.options = {
	   ...{
		 sliderTarget: '.swiper-container',
		 shuffleTarget: '.swiper-wrapper',
		 enableAt: 640,
		 shuffle: {
		   itemSelector: '.image-grid-item',
         itemSelectorLink: '.image-grid-item__link',
		 },
		 sliderOpts: {
		   slidesPerView: 'auto',
		   watchSlidesVisibility: true,
		   spaceBetween: 20,
		   centeredSlides: false,
		   loop: true,
		   navigation: {
			 nextEl: '.slider-navigation__next',
			 prevEl: '.slider-navigation__prev',
		   },
		 },
	   },
	   ...options,
	 };
   }
 
   /**
	* Run the initialization behaviors.
	*/
   init() {
	 // configure Swiper to use modules
	 Swiper.use([Navigation, Pagination]);
 
	 this.items.forEach(imageGrid => {

	   // Get the classes on the image grid.
	   const classes = [];
	   imageGrid.classList.forEach((classString, index) => {
		 if (Number.isInteger(index)) {
		   classes.push(`.${classString}`);
		 }
	   });
       
	   // Save the selector of the image grid as a property on each grid.
	   imageGrid.selector = classes.join('');
 
	   // Click the more or less if a grid item is interacted with & more or less is being enabled
	   const allItemSelectorLinks = imageGrid.querySelectorAll(
		 imageGrid.shuffle.itemSelectorLink,
	   );
 
	   allItemSelectorLinks.forEach(link => {
       if (link.closest('.more-less') != null) {
         if (link.dataset.imageGridActiveAlt !== '') {
           link.setAttribute("aria-label", link.dataset.imageGridActiveAlt);
       }
		   link.addEventListener('click', () => {
			 const moreLessLink = link
			   .closest('.more-less')
			   .querySelector('.more-less__toggle');
			 const moreLessActive = link.closest('.more-less-open');
 
			 if (moreLessActive == null && moreLessLink != null) {
			   moreLessLink.click();
			 }
		   });
		 }
	   });
	 });
   }
 
   /**
	* Load the grid or slider, depending on current window size.
	*/
  resize() {
    WindowState.on('resize', resize => {
      this.update(resize.width);
    });
  }
   
   /**
    * Update the grid state.
    *
    * @param {int} width
    *   The current window width.
    */
   update(width) {
     this.items.forEach(imageGrid => {
       if (width > imageGrid.enableAt) {
        ImageGrid.grid(imageGrid);
      } else {
        ImageGrid.slider(imageGrid);
      }
     });
   }
 
   /**
	* Create the image grid while disabling the slider, if needed.
	*
	* @param {HTMLElement} imageGrid
	*   The element to enable the image grid on.
	*/
   static grid(imageGrid) {
     if (imageGrid.hasOwnProperty('grid')) {
       return;
     }

	   if (imageGrid.hasOwnProperty('slider')) {
		 imageGrid.slider.destroy(
		   imageGrid.querySelector(imageGrid.sliderTarget),
		   true,
		 );
		 delete imageGrid.slider;
	   }
	   imageGrid.grid = new Shuffle(
		 imageGrid.querySelector(imageGrid.shuffleTarget),
		 imageGrid.shuffle,
	   );
 
	   // If image grid is inside a more/less based on items we need to recalc the height
	   if (imageGrid.closest('.more-less') !== undefined) {
		 imageGrid
		   .closest('.more-less')
		   .querySelector('[data-more-less-button]')
       		.addEventListener('click', () => {
			  imageGrid.grid.layout();
			  imageGrid.grid.update();
		   });
	   }
	 }
 
   /**
	* Create the slider while disabling the image grid, if needed.
	*
	* @param {HTMLElement} imageGrid
	*   The element to enable the image grid on.
	*/
   static slider(imageGrid) {
     if (imageGrid.hasOwnProperty('slider')) {
       return;
     }
	   if (imageGrid.hasOwnProperty('grid')) {
		 imageGrid.grid.destroy();
		 delete imageGrid.grid;
	   }
	   imageGrid.slider = new Swiper(
		 imageGrid.querySelector(imageGrid.sliderTarget),
		 imageGrid.sliderOpts,
	   );
   }
 }
 