import tabbableElements from "./tabbable";

function getContainerElements(containerElem) {
	return containerElem.querySelectorAll(tabbableElements.join(','));
}

export function removeTabIndexContainer(containerElem) {
	const elements = getContainerElements(containerElem);
  if (!elements || !elements.length) {
    return;
  }
  elements.forEach(tabbableElement => {
    tabbableElement.removeAttribute('tabindex');
  });
}

export function hideFocusContainer(containerElem) {
  const elements = getContainerElements(containerElem);
  if (!elements || !elements.length) {
    return;
  }
  elements.forEach(tabbableElement => {
    tabbableElement.setAttribute('tabindex', '-1');
  });
}