/**
 * @file
 * Tab class.
 */

import ComponentBase from '../core/component-base';
import WindowState from '../core/window-state';

/**
 * The Tab class provides an easy way to create tabs with text toggling for
 * accessibility, which turn to accordions on mobile.
 */
export default class Tab extends ComponentBase {
  /**
   * Set the object's initial state.
   *
   * @constructor
   * @param {Object} options
   *   Tab options. See object definition in the constructor below.
   */
  constructor(options = {}) {
    super('tab');

    /**
     * Create the options from supplied options and defaults. Options can be
     * specified on object creation by passing an object, or inline as data
     * attributes on the tab DOM element. Data attribute options are
     * kebab-cased versions of the options below, prefixed with data-tab.
     *
     * @property {int} breakpoint
     *   The tabs breakpoint, below which the tabs function as accordions. Set
     *   to -1 to always show tabs. This number must be in sync with the
     *   accordion markup that doubles as tab content. Defaults to 769, or the
     *   value of the [data-tab-breakpoint] attribute.
     * @property {string} buttonContainerSelector
     *   The selector of the button container element. Defaults to
     *   '.tabs__buttons', or the value of the
     *   [data-tab-button-container-selector] attribute.
     * @property {string} buttonSelector
     *   The selector of the button elements. Defaults to '.tabs__button', or
     *   the value of the [data-tab-button-selector] attribute.
     * @property {string} contentSelector
     *   The selector of the tab content elements. Defaults to '.accordion', or
     *   the value of the [data-tab-content-selector] attribute.
     *  @property {string/int} initialActiveTab
     *   'none' or integer of initial active tab,
     *   where 0 is the first tab
     *   the value of the the [data-tab-initial-active-tab] attribute
     *  @property {string} toggleTabs
     *    true/false string value indicating whether
     *    tab selection and display can be toggled on
     *    alternate clicks NOTE: the data attribute does NOT return a boolean
     * @property {string} useAnchors
     *    string value indicating whether direct anchor
     *    URLs can be shared to visit the page with specific tab active
     *    and content displayed. Also sets scroll behavior when anchor is added to URL
     *    by clicking button. Values: 'none', 'still', 'jump', 'smooth'
     *
     */
    this.options = {
      ...{
        breakpoint: 768,
        buttonContainerSelector: '.tabs__buttons',
        buttonSelector: '.tabs__button',
        contentSelector: '.accordion',
        accordionButtonSelector: '.accordion__button',
        initialActiveTab: 0,
        toggleTabs: 'false',
        useAnchors: 'false',
      },
      ...options,
    };

    this.useAnchors = this.options.useAnchors;
  }

  /**
   * Initialize each tab.
   */
  init() {
    this.items.forEach(tab => {
      const buttonsContainer = tab.querySelector(tab.buttonContainerSelector);
      const buttons = buttonsContainer.querySelectorAll(tab.buttonSelector);
      const contents = tab.querySelectorAll(tab.contentSelector);

      // Update class property to be passed to static showTabs function
      this.useAnchors = tab.useAnchors;

      // Update initial state based on anchor/id reference in URL
      const tabAnchor = window.location.hash.substr(1);
      if (tab.useAnchors !== 'none' && tabAnchor) {
        const targetPanel = tab.querySelector(`#${tabAnchor}`);
        const contentPanels = [...tab.querySelectorAll(tab.contentSelector)];

        if (
          tabAnchor &&
          targetPanel !== undefined &&
          tabAnchor === targetPanel.id
        ) {
          tab.initialActiveTab = contentPanels.indexOf(targetPanel);
        }
      }

      // Set the initial state, if none isn't used set the initial tab, otherwise hide them all
      if (tab.initialActiveTab !== 'none') {
        Tab.showTab(buttons, contents, tab.initialActiveTab, this.useAnchors);
      } else if (tab.initialActiveTab === 'none') {
        Tab.hideTab(buttons, contents);
      }

      // Add the button click behavior.
      buttons.forEach((button, index) => {
        button.addEventListener('click', () => {
          if (
            tab.toggleTabs === 'true' &&
            button.getAttribute('aria-selected') === 'true'
          ) {
            Tab.hideTab(buttons, contents, index);
          } else {
            Tab.showTab(buttons, contents, index, this.useAnchors);
          }
        });
      });
    });
  }

  /**
   * Update each tab on resize.
   */
  resize() {
    WindowState.on('resize', resize => {
      this.items.forEach(tab => {
        const buttonsContainer = tab.querySelector(tab.buttonContainerSelector);
        const tabButtons = buttonsContainer.querySelectorAll(tab.buttonSelector);
        if (tab.breakpoint > -1) {
          // Disable the tab buttons when below the breakpoint.
          tabButtons.forEach(button => {
            if (resize.width < tab.breakpoint) {
              button.setAttribute('disabled', 'disabled');
            } else {
              button.removeAttribute('disabled');
            }
          });
          // Hide/show content on breakpoint resize.
          if (resize.width >= tab.breakpoint) {
            const contents = tab.querySelectorAll(tab.contentSelector);
            let tabShown = false;
            contents.forEach((content, index) => {
              const contentButton = content.querySelector(tab.accordionButtonSelector);
              if (!tabShown && contentButton.getAttribute('aria-expanded') === 'true') {
                content.setAttribute('aria-hidden', 'false');
                Tab.showTab(tabButtons, contents, index, this.useAnchors);
                tabShown = true;
              } else {
                content.setAttribute('aria-hidden', 'true');
              }
            });
          }
        }
      });
    });
  }

  /**
   * Show a tab and hide all others.
   *
   * @param {NodeLst} buttons
   *   The buttons to toggle.
   * @param {Array} contents
   *   The contents to toggle, an array of HTMLElements.
   * @param {int} index
   *   The index of the tab to show.
   */
  static showTab(buttons, contents, index, anchor) {

    const thisButton = buttons[index];
    const thisContent = contents[index];

    // Reset all to tabs and contents to unselected/hidden.
    // Show the correct tab and contents.
    buttons.forEach(button => {
      if (button === thisButton) {
        button.setAttribute('aria-selected', 'true');
      } else {
        button.setAttribute('aria-selected', 'false');
      }
    });
    contents.forEach(content => {
      if (content === thisContent) {
        content.setAttribute('aria-hidden', 'false');
      } else {
        content.setAttribute('aria-hidden', 'true');
      }
    });

    // Set style of motion when anchor is appended to URL
    const targetTabId = `#${thisContent.id}`;
    switch (anchor) {
      case 'still':
        history.pushState(null, null, targetTabId);
        break;
      case 'jump':
        window.location.hash = targetTabId;
        break;
      case 'smooth':
        document
          .querySelector(targetTabId)
          .scrollIntoView({ behavior: 'smooth' });
        history.pushState(null, null, targetTabId);
      default:
        break;
    }
  }

  /**
   * Hide all tabs when an active tab is clicked.
   *
   * @param {NodeLst} buttons
   *   The buttons to toggle.
   * @param {Array} contents
   *   The contents to toggle, an array of HTMLElements.
   * @param {int} index
   *   The index of the tab that was active and clicked.
   */
  static hideTab(buttons, contents, index) {
    // Reset all tabs and contents to unselected/hidden.
    buttons.forEach(button => {
      button.setAttribute('aria-selected', 'false');
    });
    contents.forEach(content => {
      content.setAttribute('aria-hidden', 'true');
    });
  }
}
