/**
 * @file
 * JavaScript entrypoint file for the theme.
 *
 * All JavaScript added to the theme must go through this Webpack entrypoint.
 * Modules are included with import statements. Remove unused modules.
 * Import any module-specific CSS as well, and Webpack will bundle it in
 * /assets/dist/app.css. Module CSS paths are relative to the node_modules
 * folder, i.e. '@glidejs/glide/dist/css/glide.core.css' for Glide's CSS.
 */

import './compat/ie11';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox.css';
import prefersReducedMotion from './utils/prefers-reduced-motion';
import { AnimateSingle, AnimateSequence } from './modules/animation';
import AccessibleMenu from './modules/accessible-menu';
import Accordion from './modules/accordion';
import ButtonToggle from './modules/button-toggle';
import HeaderState from './modules/header-state';
import Tab from './modules/tab';
import Table from './modules/table';
import Slider from './modules/slider';
import OhoVideo from './modules/video';
import CookieBanner from './modules/cookie-banner';
import MoreOrLess from './modules/more-or-less';
import ImageGrid from './modules/image-grid';

(() => {
  /**
   * Can use this selector for styles that need to be applied before JS elements run.
   */
  document.querySelector('html').classList.add('oho-js-pre');

  const prefersRedMotion = prefersReducedMotion();

  /**
   * Create standard tabs.
   */
  const tabs = new Tab();
  tabs.add('.tabs').run();

  /**
   * Wrap tables for responsive styles.
   */
  const tables = new Table();
  tables.add('#main-content table').run();

  /**
   * Add the sticky header.
   */
  const headerState = new HeaderState();
  headerState.add('.site-header').run();

  /**
   * Init The Cookie Banner
   */
	CookieBanner();

  /**
   * Add animations. @TODO
   */
  if (!prefersRedMotion) {
    const singleAnimation = new AnimateSingle();
	  singleAnimation.add('.oho-animate-single').run();
	  
	  const sequenceAnimation = new AnimateSequence();
    sequenceAnimation.add('.oho-animate-sequence').run();
  }

  /**
   * Global Button Toggle controlled by data attrs.
   */
  const globalButtonToggle = new ButtonToggle();
  globalButtonToggle.add('.button-toggle').run();
	
  const searchButtonToggle = new ButtonToggle();
  searchButtonToggle.add('.site-header__search__button').run();

  // Toggles search when click "go to search" button.
  const searchButton = document.querySelector('.site-header__search__button');
  const searchGoTo = document.querySelectorAll('.site-header__search__goto');
  searchGoTo.forEach(button => {
    button.addEventListener('click', (event) => {
      event.stopPropagation();
      searchButton.toggleButton(true, true);
    });    
  });

  /**
   * Autoplay Videos
   */
	const video = new OhoVideo({
    autoplay: !prefersRedMotion
  });
	video.add('.autoplay-video-container').run();

  const accordion = new Accordion();
  accordion.add('.accordion.accordion-init').run();

  // If there are tables inside of an accordion, fire the init
  accordion.on('toggle', event => {
    if (event.accordion.isOpen === true) {
      const allTables = event.accordion.querySelectorAll('table');
      if (allTables.length) {
        allTables.forEach(accordionTable => {
          accordionTable.toggleResponsiveTable();
        });
      }
    }
  });

  /**
   * Add the accessible Main menu.
   */
  const accessibleMainMenu = new AccessibleMenu();
  accessibleMainMenu.add('.site-header__main__menu').run();

  /**
   * Add the accessible sidebar menu.
   */
  const accessibleSidebarMenu = new AccessibleMenu({
    expandActiveTrail: true,
  });
	accessibleSidebarMenu.add('.sidebar-menu').run();
	
	/**
   * Create standard image grids.
   */
  const imageGrid = new ImageGrid();
  imageGrid.add('.image-grid').run();

  /**
   * Create standard more or less.
   */
  const moreOrLess = new MoreOrLess();
  moreOrLess.add('.more-less').run();

  try {
    // Flexible cards slider.
    const slider = new Slider({
      minSlides: 4,
      sliderOpts: {
        slidesPerView: 1,
        watchSlidesVisibility: true,
        spaceBetween: 8,
        centeredSlides: false,
        loop: false,
        navigation: {
          nextEl: '.slider-navigation__next',
          prevEl: '.slider-navigation__prev',
        },
        breakpoints: {
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        },
      },
    });
    slider.add('.cards-flex-section').run();
  } catch { }

  try {
    // Gallery slider.
    const slider = new Slider({
      sliderOpts: {
        slidesPerView: 1,
        watchSlidesVisibility: true,
        spaceBetween: 8,
        centeredSlides: false,
        loop: false,
        navigation: {
          nextEl: '.slider-navigation__next',
          prevEl: '.slider-navigation__prev',
        },
        breakpoints: {
          1024: {
            spaceBetween: 40,
          },
        },
      },
    });
    slider.add('.gallery').run();
  } catch { }

  try {
    // Profiles slider.
    const slider = new Slider({
      sliderOpts: {
        slidesPerView: 1,
        watchSlidesVisibility: true,
        spaceBetween: 8,
        centeredSlides: false,
        loop: false,
        navigation: {
          nextEl: '.slider-navigation__next',
          prevEl: '.slider-navigation__prev',
        },
        breakpoints: {
          640: {
            slidesPerView: 2,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
      },
    });
    slider.add('.profiles-section').run();
  } catch { }

  // Testimonials slider.
  try {
    const slider = new Slider({
      sliderOpts: {
        slidesPerView: 1,
        watchSlidesVisibility: true,
        spaceBetween: 0,
        centeredSlides: false,
        loop: true,
        navigation: {
          nextEl: '.slider-navigation__next',
          prevEl: '.slider-navigation__prev',
        },
      },
    });
    slider.add('.testimonials').run();
  } catch { }

  /**
   * Add a class to the body to style against if this JavaScript did not load
   * correctly or has an error which blocks JavaScript execution.
   */
  document.querySelector('html').classList.add('oho-js');
})();

/**
 * Tells Fancybox not to autoplay videos.
 */
Fancybox.bind('[data-fancybox]', {
  Html: {
    video: {
      autoplay: false,
    },
  },
});
