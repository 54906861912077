/**
 * @file
 * Animation classes.
 */

import CounterUp from 'counterup2';
import AnimationBase from '../core/animation-base';
import { centerInViewport } from '../utils/in-viewport';

/**
 * The AnimateCount class is a simple way to toggle number counting from zero to
 * the end number in the HTML when the element center is in the viewport.
 */
export class AnimateCount extends AnimationBase {
  /**
   * Set the object's initial state.
   *
   * @constructor
   * @param {Object} options
   *   Count animation options. See object definition in the constructor below.
   */
  constructor(options = {}) {
    super();
    /**
     * Create the options from supplied options and defaults. Options can be
     * specificed on object creation by passing an object, or inline as data
     * attributes on the animation DOM element. Data attribute options are
     * kebab-cased versions of the options below, prefixed with data-animation.
     *
     * @property {int} delay
     *   The delay between each number animation, in ms. Defaults to 16, or the
     *   value of the [data-animation-delay] attribute.
     * @property {int} duration
     *   The total animation duration, in ms. Defaults to 1000, or the value of
     *   the [data-animation-duration] attribute.
     *
     * @see https://github.com/bfintal/Counter-Up2
     */
    this.options = {
      ...this.options,
      ...{
        delay: 16,
        duration: 1000,
        intersectionObserver: {
          root: null,
          rootMargin: '0%',
          threshold: 1,
        },
      },
      ...options,
    };
  }

  /**
   * Animation logic for each counter.
   *
   * @param {Element} item
   *   The counter to animate.
   */
  animate(item) {
    /**
     * Emit an 'animate' event.
     *
     * @type {Object}
     * @property event
     *   The triggering animation event.
     * @property {HTMLElement} item
     *   The animation Item that triggered the event.
     * @param {Element} state
     *   The current animation state 'in/true', or 'out/false/initial'.
     *   There is no initial animated out state by CounterUp
     */

    item.runAnimation = (state = null) => {
      if (state == 'in' || state == true) {
        item.classList.remove(this.readyClass);
        CounterUp(item, {
          duration: item.duration,
          delay: item.delay,
        });
        item.animated = true;
      } else {
        item.classList.add(this.readyClass);
        CounterUp(item, {
          action: 'stop',
        });
        item.animated = false;
      }
      this.emit('animate', { item });
    };
    item.runAnimation(true);
  }

  /**
   * Viewport check for browsers not using the IntersectionObserver API.
   *
   * @param {Element} item
   *   The element to check for animation status.
   *
   * @return {Boolean}
   *   True if the item should animate.
   */
  // eslint-disable-next-line class-methods-use-this
  legacyShouldAnimate(item) {
    return centerInViewport(item);
  }
}

/**
 * The AnimateSingle class is a simple way to toggle animate classes on single
 * elements when the element center is in the viewport.
 */
export class AnimateSingle extends AnimationBase {
  /**
   * Animation logic for each item.
   *
   * @param {Element} item
   *   The item to animate.
   */
  animate(item) {
    /**
     * Emit an 'animate' event.
     *
     * @type {Object}
     * @property event
     *   The triggering animation event.
     * @property {HTMLElement} item
     *   The animation Item that triggered the event.
     * @param {Element} state
     *   The current animation state 'initial', 'in', or 'out'.
     */
    item.runAnimation = (state = null) => {
      if (state == 'initial') {
        item.classList.add(this.readyClass);
        item.classList.remove(this.animateInClass, this.animateOutClass);
        item.animated = false;
      }
      if (state == 'in' || state == true) {
        item.classList.remove(this.readyClass, this.animateOutClass);
        item.classList.add(this.animateInClass);
        item.animated = true;
      } else {
        item.classList.remove(this.readyClass, this.animateInClass);
        item.classList.add(this.animateOutClass);

        item.animated = false;
      }
      this.emit('animate', { item });
    };
    item.runAnimation('in');
  }
}

/**
 * The AnimateSequence class is a simple way to toggle animate classes on group
 * of elements when the container element is in the viewport. The sequence
 * elements are animated in order on a delay.
 */
export class AnimateSequence extends AnimationBase {
  /**
   * Set the object's initial state.
   *
   * @constructor
   * @param {Object} options
   *   Sequence animation options. See object definition in the constructor
   *   below.
   */
  constructor(options = {}) {
    super();
    /**
     * Create the options from supplied options and defaults. Options:
     *
     * @property {int} delay
     *   The delay between subsequent animations of each element, in ms.
     *   Defaults to 400, or the value of the [data-animation-delay] attribute.
     * @property {string} itemsSelector
     *   The selector for each of the items to animate in sequence. Defaults to
     *   '.oho-animate', or the value of the [data-animation-items-selector]
     *   attribute.
     */
    this.options = {
      ...this.options,
      ...{
        delay: 250,
        itemsSelector: '.oho-animate',
      },
      ...options,
    };
  }

  /**
   * Add elements to animate, and add the initial class as well.
   *
   * @param {string} selector
   *   The selector of elements to add, in a format querySelectorAll() can use.
   * @param {HTMLDocument|HTMLElement} [context=document] context
   *   An optional context to search within. If no context is passed, the entire
   *   document is searched.
   * @return {AnimateSingle}
   *   The current object, for method chaining.
   */
  add(selector, context = document) {
    super.add(selector, context);
    this.items.forEach(item => {
      const sequenceItems = item.querySelectorAll(item.itemsSelector);
      sequenceItems.forEach(sequenceItem =>
        sequenceItem.classList.add(this.readyClass),
      );
    });
    return this;
  }

  /**
   * Animation logic for a sequency of items.
   *
   * @param {Element} item
   *   The sequence items container.
   */
  animate(item) {
    /**
     * Emit an 'animate' event.
     *
     * @type {Object}
     * @property event
     *   The triggering animation event.
     * @property {HTMLElement} item
     *   The animation Item that triggered the event.
     * @param {Element} state
     *   The current animation state 'initial', 'in', or 'out'.
     */

    item.runAnimation = (state = null) => {
      const sequenceItems = item.querySelectorAll(item.itemsSelector);

      if (state == 'initial') {
        item.classList.add(this.readyClass);
        item.classList.remove(this.animateInClass, this.animateOutClass);
        sequenceItems.forEach(
          (sequenceItem, sequenceItemIndex) =>
            // Disabling ESLint's warnings about nested callbacks here.
            item.classList.add(this.readyClass),
          item.classList.remove(this.animateInClass, this.animateOutClass),
        );
        item.animated = false;
      }
      if (state == 'in' || state == true) {
        item.classList.remove(this.readyClass, this.animateOutClass);
        item.classList.add(this.animateInClass);
        item.animated = true;
      } else {
        item.classList.remove(this.readyClass, this.animateInClass);
        item.classList.add(this.animateOutClass);

        item.animated = false;
      }

      if (state == 'in' || state == true) {
        item.classList.remove(this.readyClass);
        sequenceItems.forEach((sequenceItem, sequenceItemIndex) =>
          // Disabling ESLint's warnings about nested callbacks here.
          setTimeout(
            () => {
              sequenceItem.classList.remove(
                this.readyClass,
                this.animateOutClass
              );
              sequenceItem.classList.add(this.animateInClass); // eslint-disable-line
          }, item.delay * sequenceItemIndex),
        );
      } else {
        item.classList.add(this.readyClass);
        sequenceItems.forEach((sequenceItem, sequenceItemIndex) =>
          // Disabling ESLint's warnings about nested callbacks here.
          setTimeout(
            () => {
            sequenceItem.classList.remove(
              this.readyClass,
              this.animateInClass,
            );
            sequenceItem.classList.add(this.animateOutClass); // eslint-disable-line
          }, item.delay * sequenceItemIndex),
        );
      }
      this.emit('animate', { item });
    };
    item.runAnimation(true);
  }
}
