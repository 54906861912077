/**
 * @file
 * Table class.
 */

 import ComponentBase from '../core/component-base';
 import WindowState from '../core/window-state';

 /**
  * The Table class helps in making tables usable on mobile. The class will wrap
  * all tables in an element and add an active class when the table width is
  * creater than the wrapping element's width. This allows for styling, like an
  * overflow, to be added conditionally only when the table needs it.
  */
 export default class Table extends ComponentBase {
   /**
    * Set the object's initial state.
    *
    * @constructor
    * @param {Object} options
    *   Table options. See object definition in the constructor below.
    */
   constructor(options = {}) {
     super('table');

     /**
      * Create the options from supplied options and defaults. Options can be
      * specificed on object creation by passing an object, or inline as data
      * attributes on the accordion DOM element. Data attribute options are
      * kebab-cased versions of the options below, prefixed with data-accordion.
      *
      * @property {string} wrapperElement
      *   The element to wrap all tables with. Defaults to 'div'.
      * @property {string} wrapperClass
      *   The class to add to the wrapper element. Defaults to 'mobile-table'.
      * @property {string} wrapperActiveClass
      *   The class to add to the wrapper element when the table width is greater
      *   than the wrapper width. Defaults to 'mobile-table--scroll'.
      */
     this.options = {
       ...{
         wrapperElement: 'div',
         wrapperClass: 'mobile-table',
         wrapperActiveClass: 'mobile-table--scroll',
       },
       ...options,
     };
   }

   /**
    * Initialize each table, wrapping it in the element and saving the warpper as
    * a property on the table object.
    */
   init() {
     this.items.forEach(table => {
       table.wrapper = document.createElement(this.options.wrapperElement);
       table.wrapper.classList.add(this.options.wrapperClass);
       table.parentNode.insertBefore(table.wrapper, table);
       table.wrapper.appendChild(table);

       // Init toggle function on setup
       window.addEventListener('load', () => {
         table.toggleResponsiveTable(table);
       });

       /**
       * recalculate a mobile table.
       *
       * @param {bool|null} state
       *   The state to toggle, true corresponds to a clicked state.
       */
       table.toggleResponsiveTable = () => {
         if(initResponsiveTable(table) == true) {
           responsiveTable(table, true);
         } else {
           responsiveTable(table, false);
         }

         /**
          * Emit an 'toggle' event on clicks.
          *
          * @type {Object}
          * @property event
          *   The triggering toggle event.
          * @property {HTMLElement} button
          *   The table that triggered the event.
          */
         this.emit('toggle', { table });
       }
     });
   }

   /**
    * Update each table on resize, adding the active class if the table width is
    * greater than the wrapper width.
    */
   resize() {
     this.items.forEach(table => {
     });
     WindowState.on('resize', () => {
       this.items.forEach(table => {
        table.toggleResponsiveTable(table);
       });
     });
   }
 }

 /**
   * Check if the table needs to be initialized
   *
   * @param {HTMLElement} table
   *   The table of the size that is in questions
 */
 function initResponsiveTable(table) {
   return table.offsetWidth > table.wrapper.offsetWidth;
 }

 /**
  * A function to construct and deconstruct a responsive table
  *
  * @param {HTMLElement} table
  *   The table in which the responsive attributes are being added
  * @param {Boolean} init
  *   Whether to init the table or not
  */
 function responsiveTable(table, init) {
     /**
     * Store the individual widths of the table and its container to check against each other.
     * If we do not, the widths read the same after the mobile table classes init
     */
     const tableWrapperWidth = table.wrapper.offsetWidth;
     const tableWidth = table.offsetWidth;

     // Setup a function to bind to a scroll event
     function tableScroll(tableWrapper) {
       if (tableWrapper.scrollLeft > 2) {
         tableWrapper.classList.add('scrolled');
       } else {
         tableWrapper.classList.remove('scrolled');
       }
       if (table.wrapper.scrollLeft >= tableWidth - tableWrapperWidth) {
         tableWrapper.classList.add('scrolled-to-edge');
       } else {
         tableWrapper.classList.remove('scrolled-to-edge');
       }
     }

   if(init == true) {
     // add the initialized class
     table.wrapper.classList.add(table.wrapperActiveClass);

     /**
     * bind @function tableScroll to scroll event listener
     */
     table.wrapper.addEventListener('scroll', () => {
       tableScroll(table.wrapper);
     });
   } else if(init == false) {
    table.wrapper.classList.remove(table.wrapperActiveClass);
  }
 }